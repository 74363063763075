/* Wrapper for blog page  */
.wrapperforblog {
  max-width: 800px; /* Adjust width as needed */
  margin: 0 auto;
}

@media only screen and (max-width: 600px) {
  .wrapperforblog {
    max-width: 100%; /* Full width on smaller screens */
    padding: 10px; /* Adjust padding for smaller screens */
  }
}

/* container for BLOG written in between  */

.FontSize {
  font-size: 18px;
  font-family: "merriweather";
  color: #454545;
}
.BgColor {
  background-color: #4c8577;
  color: #ffffff;
}
