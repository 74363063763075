.FontSize {
  font-size: 18px;
  font-family: "merriweather";
  color: #454545;
}
.FootClasslink {
  font-size: 19px;
  font-weight: 600;
  color: #454545;
}
