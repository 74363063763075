/* Writing a common file for components css */
.Smallline {
  width: 100%;
  height: 5px;
  background-color: #f5e663 /*#e6be8a*/;
}

.TopFirst {
  width: 100%;
  height: 15px;
  /* background-color: #2c666e; */
  background-color: #4c8577;
}
.VerySmallLine {
  width: 100%;
  height: 2px;
  background-color: #ffd700 /*#e6be8a*/;
}
.PaDd {
  padding: 40px 0px 0px 0px;
  margin: 10px 0px 40px 0px;
}
.FindME {
  font-weight: 800;
  font-size: 20px;
}
.unClass {
  text-decoration: none;
  list-style-type: none;
  padding: 0px 0px 0px 3px;
  margin: 0;
  color: #454545;
}
.unClass1 {
  text-decoration: none;
  list-style-type: none;
  padding: 0px 0px 0px 0px;
  margin: 0;
}
.displayF {
  display: flex;
}
.FootClass {
  font-size: 19px;
  font-weight: 600;
  color: #454545;
}
.FootClass1 {
  font-size: 17px;
  font-weight: 600;
  color: #454545;
}
