.Give-heading-weight {
  font-weight: 900;
  font-size: 40px;
  color: #454545;
  margin-top: 60px;
}
.Black-background {
  background-color: #222222;
  padding: 8px;
}
.Halfing-div {
  margin-right: 30%;
}
.SpanCode {
  background-color: #222222;
  color: #e6e6e6;
  font-weight: 400;
}
/* Giving font size to all the fonts in the content 
 */
/* /////////////////////////////////////////////  */
.FontSize {
  font-size: 18px;
  font-family: "merriweather";
  color: #454545;
}
.FontSize1 {
  font-size: 16px;
  font-family: "merriweather";
  color: #454545;
}
.HeadFont {
  font-size: 35px;
  padding-top: 35px;
  font-weight: 800;
  color: #454545;
}
.SubHeadFont {
  font-size: 22px;
  padding-top: 15px;
  font-weight: 700;
  color: #454545;
}
.SpanColour {
  color: #ff0000;
  font-size: 18px;
  font-family: "merriweather";
}
/* //////////////////////////////////////////////  */
.Anchor {
  text-decoration: none;
  color: inherit; /* Inherit text color from parent */
  background-color: transparent;
}
@media only screen and (max-width: 500px) {
  .Halfing-div {
    margin-right: 0%;
  }
  .Give-heading-weight {
    font-weight: 900;
    font-size: 30px;
    color: #454545;
    margin-top: 20px;
  }
  .FontSize {
    font-size: 15px;
    font-family: "merriweather";
    color: #454545;
  }
  .FontSize1 {
    font-size: 15px;
    font-family: "merriweather";
    color: #454545;
  }
  .HeadFont {
    font-size: 30px;
    padding-top: 35px;
    font-weight: 800;
    color: #454545;
  }
  .SpanColour {
    color: #ff0000;
    font-size: 15px;
    font-family: "merriweather";
  }
  .SubHeadFont {
    font-size: 21px;
    padding-top: 15px;
    font-weight: 700;
    color: #454545;
  }
}
