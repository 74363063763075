.wrapper {
  max-width: 1000px; /* Adjust width as needed */
  margin: 0 auto;
}

@media only screen and (max-width: 600px) {
  .wrapper {
    max-width: 100%; /* Full width on smaller screens */
    padding: 10px; /* Adjust padding for smaller screens */
  }
}

.GiveWeight {
  font-weight: 900;
  color: #454545;
  font-size: x-large;
}

.GiveWeight:hover {
  font-weight: 900;
  color: #454545;
  /* color: #30362f; */
  font-size: x-large;
}
.GiveWeightNav {
  font-weight: 800;
  /* color: #4c8577; */
  color: #4c8577;
  font-size: large;
}
.GiveWeightNav:hover {
  font-weight: 800;
  color: #7ebc89;
  font-size: large;
  text-decoration: underline;
}
/* Firebase link files css to change the padding and other things given to it  */
.FirebasePad {
  padding: 20px;
  margin-top: 15px;
}
.PositionHeading {
  margin-top: 20px;
  /* margin-left: 10px; */
  font-weight: 800;
  text-decoration: none;
}
.PublishWriting {
  margin-left: 10px;
  font-weight: 400;
  font-style: italic;
}
.Publishwriting-content {
  margin-left: 10px;
  margin-top: 30px;
  margin-bottom: 10px;
}
.Publishwriting-text {
  font-size: 18px;
  font-family: "merriweather";
}
@media only screen and (max-width: 500px) {
  .Publishwriting-text {
    font-size: 15px;
    font-family: "merriweather";
  }
}

.text-decor {
  text-decoration: none;
}
