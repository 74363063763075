.blogheadforProfile {
  font-weight: 900;
  font-size: 45px;
  color: #454545;
  padding-top: 80px;
  padding-bottom: 80px;
}
.FontSize1 {
    font-size: 16px;
    font-family: "merriweather";
    color: #454545;
  }
@media only screen and (max-width: 600px) {
  .blogheadforProfile {
    font-weight: 900;
    font-size: 35px;
    color: #454545;
    padding-top: 30px;
    padding-bottom: 15px;
  }
}
.ImgRound {
  border-radius: 50%;
  width: 120px;
}
.ProfileName {
  font-weight: 900;
  font-size: 50px;
  color: #454545;
  margin-top: 26px;
}
.ProfileName1 {
  font-weight: 900;
  font-size: 30px;
  color: #454545;
  margin-top: 10px;
}
