/* Wrapper for blog page  */
.wrapperforblog {
  max-width: 800px; /* Adjust width as needed */
  margin: 0 auto;
}

@media only screen and (max-width: 500px) {
  .wrapperforblog {
    max-width: 100%; /* Full width on smaller screens */
    padding: 10px; /* Adjust padding for smaller screens */
  }
  
}

/* container for BLOG written in between  */
.bloghead {
  font-weight: 900;
  font-size: 45px;
  color: #454545;
  display: flex;
  justify-content: center;
  padding: 70px;
}
/* Written for the subscribing part in the page  */
.Subscribe {
  padding: 15px;
  /* color: #07393c; */
  color: #4e6e58;
  /* font-weight: 700; */
  display: flex;
}
.Subscribe:hover {
  padding: 15px;
  /* color: #07393c; */
  color: #7ebc89;
  /* font-weight: 800; */
  display: flex;
}
.words {
  margin-left: 5px;
  font-weight: 700;
  font-family: "merriweather";
}
.words:hover {
  margin-left: 5px;
  text-decoration: underline;
}
